import {primitiveAtomWithLS} from '@app/utils/reatom'
import {createAtom} from '@reatom/core'
import {createBooleanAtom, createPrimitiveAtom} from '@reatom/core/primitives'
import {RowSelectionState} from '@tanstack/react-table'
import {InWarehouseTableColumnId} from '../content/table-by-items/content/use-columns'

export type InWarehouseViewItem = InWarehouseItem

export interface InWarehouseItem {
  id: string
  airtableLink: string
  registrationDate: Date | null
  deadlineDate?: Date | null
  brand: string
  providerHSCode: string
  orderComments: string
  amoId: string
  originalName: string
  invoiceName: string
  catalogueId: string
  quantity: number
  sumCost: number
  invoicePrice: number
  itemCost: number
  temperatureRegime: string
  hazmatComment: string
  lot: string
  source_number: string
  locations: Array<string>
  expiredDate: Date | null
  currencyId: string
  warehouseAreaId: string | null
  exportStatuses: Array<string>
  exportComment: string
  countryOfOrigin: string
  boxNumber: Array<string>
  sdComment: string
  sdExp: string
  tagToHub: string
  tagToTransit: string
  tagToRu: string
  hub: string
  transit: string
  prtComments: string
  provider: string
  providerHazmatType: string
  express: boolean
  isStock: boolean
  procurement: string
  responsibleSCD: string
  additionalCosts: number
  extraChargePercent: number
  orderNumbering: string
  hsnCodes: string
  store: string
}

export const inWarehouseRedefinedColumnsVisibilityAtom = primitiveAtomWithLS<
  TypedObject<InWarehouseTableColumnId, boolean>
>(
  {
    deadlineDate: false,
  },
  'InWarehouse.RedefinedColumnsVisibilityAtom',
)

export const inWarehouseAtoms = {
  loading: createBooleanAtom(false),
  selectedRows: createPrimitiveAtom<RowSelectionState>({}),
  items: createPrimitiveAtom<Record<string, InWarehouseItem>>({}),
}

export const inWarehouseSelectedRowIdsAtom = createAtom(
  {selectedRows: inWarehouseAtoms.selectedRows},
  track => Object.keys(track.get('selectedRows')),
)
