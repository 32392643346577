import { logError } from '@appscience/utils'
import { i18n_get } from '../../../lng/i18n'
import { ColumnId } from './common'

const EMPTY_STRING = ''

// eslint-disable-next-line complexity
export function getColumnTitle(id: ColumnId | string): string {
  switch (id as ColumnId) {
    case 'id':
      return i18n_get.t('Common.Id')
    case 'airtableLink':
      return i18n_get.t('Common.AirtableLink')
    case 'actions':
    case 'select':
      return EMPTY_STRING
    case 'express':
      return i18n_get.t('Common.Express')
    case 'esdFixed':
      return i18n_get.t('Common.EsdFixed')
    case 'transferredForPaymentDate':
      return i18n_get.t('Common.TransferredForPaymentDate')
    case 'salesComment':
      return i18n_get.t('Common.SalesComment')
    case 'poNumber':
      return i18n_get.t('Common.NumberPO')
    case 'toHub0':
      return i18n_get.t('Common.SentToHub0')
    case 'trackHub0':
      return i18n_get.t('Common.TrackToHub0')
    case 'hub0':
      return i18n_get.t('Common.HUB0')
    case 'paymentPriority':
      return i18n_get.t('Common.PaymentPriority')
    case 'discountCost':
      return i18n_get.t('Common.DiscountCost')
    case 'unitCost':
      return i18n_get.t('Common.UnitCost')
    case 'currency':
      return i18n_get.t('Common.Currency')
    case 'orderSum':
      return i18n_get.t('Common.OrderSum')
    case 'isKit':
    case 'kit':
      return i18n_get.t('Common.Kit')
    case 'orderSumUSD':
      return i18n_get.t('Common.OrderSumUSD')
    case 'originCountry':
      return i18n_get.t('Common.CountryOfOrigin')
    case 'costSavings':
      return i18n_get.t('Common.CostSaving')
    case 'overpayment':
      return i18n_get.t('Common.Overpayment')
    case 'sample':
      return i18n_get.t('Common.Sample')
    case 'discount':
      return i18n_get.t('Common.Discount')
    case 'discountComment':
      return i18n_get.t('Common.DiscountComment')
    case 'providerComment':
      return i18n_get.t('Common.ProviderComment')
    case 'invoiceSum':
      return i18n_get.t('Common.InvoiceSum')
    case 'details':
      return i18n_get.t('Common.Details')
    case 'arrived':
      return i18n_get.t('Common.Arrived')
    case 'esdFix':
    case 'esdFixOriginal':
      return i18n_get.t('Common.ESDFix')
    case 'orderId':
      return i18n_get.t('Common.OrderId')
    case 'index':
      return ''
    case 'invoiceDate':
      return i18n_get.t('Common.InvoiceDate')
    case 'fromWarehouseId':
      return i18n_get.t('Common.SendFrom')
    case 'vendorPrice':
      return i18n_get.t('Common.VendorPrice')
    case 'itemParts':
      return i18n_get.t('Common.ItemParts')
    case 'invoiceNumbers':
      return i18n_get.t('Common.InvoiceNumbers')
    case 'itemDetails':
      return i18n_get.t('Common.ItemDetails')
    case 'extraChargePercent':
      return i18n_get.t('Common.ExtraChargePercent')
    case 'extraChargeAmount':
      return i18n_get.t('Common.ExtraChargeAmount')
    case 'partnersInterest':
      return i18n_get.t('Common.PartnerInterest')
    case 'partnerInvoice':
      return i18n_get.t('Common.PartnerInvoice')
    case 'reasonOfCancel':
      return i18n_get.t('Common.ReasonOfCancel')
    case 'sdComment':
      return i18n_get.t('Common.SDComment')
    case 'sdExp':
      return i18n_get.t('Common.SDExp')
    case 'toWarehouseId':
      return i18n_get.t('Common.SendTo')
    case 'additionalCosts':
      return i18n_get.t('Common.AdditionalCosts')
    case 'deadlineTimestamp':
      return i18n_get.t('Common.Deadline')
    case 'deadlineDate':
      return i18n_get.t('Common.DL')
    case 'deadlineDate2':
      return i18n_get.t('Common.DL2')
    case 'exef':
      return i18n_get.t('Common.EXEF')
    case 'paymentType':
      return i18n_get.t('Common.PaymentType')
    case 'paymentDetails':
      return i18n_get.t('Common.PaymentDetailsTable')
    case 'exefType':
      return i18n_get.t('Common.EXEFType')
    case 'purchaseDate':
      return i18n_get.t('Common.OrderDeadline')
    case 'createdDate':
      return i18n_get.t('Common.CreatedDate')
    case 'priority':
      return i18n_get.t('Common.Priority')
    case 'orderTimestamp':
    case 'orderDate':
      return i18n_get.t('Common.OrderDeadline')
    case 'expiredDate':
      return i18n_get.t('Common.ExpirationDate')
    case 'plannedDeliveryDate':
    case 'plannedDepartureDate':
      return i18n_get.t('Common.PlannedDepartureDate')
    case 'departureDate':
      return i18n_get.t('Common.DepartureDate')
    case 'nextCheckTimestamp':
      return i18n_get.t('Common.NextCheck')
    case 'registrationDate':
      return i18n_get.t('Common.AcceptanceDate')
    case 'shipmentTimestamp':
    case 'esdDate':
    case 'latestEsdDate':
      return i18n_get.t('Common.ESD')
    case 'paidDate':
      return i18n_get.t('Common.PaidDate')
    case 'amoId':
      return i18n_get.t('Common.AMO')
    case 'bankAccount':
      return i18n_get.t('Common.BankAccount')
    case 'prevWarehouseId':
      return i18n_get.t('Common.PrevWarehouse')
    case 'exportStatuses':
      return i18n_get.t('Common.ExportStatus')
    case 'name':
      return i18n_get.t('Common.ItemInvoiceName')
    case 'brand':
      return i18n_get.t('Common.Brand')
    case 'clientName':
      return i18n_get.t('Common.Client')
    case 'billPosition':
      return i18n_get.t('Common.InvoicePosition')
    case 'supposedStore':
    case 'store':
      return i18n_get.t('Common.Store')
    case 'vendorRecommendation':
      return i18n_get.t('Common.VendorRecommendation')
    case 'quantity':
      return i18n_get.t('Common.Quantity')
    case 'operationLetitId':
      return i18n_get.t('Common.OperationLetitId')
    case 'hsCodeSecondary':
      return i18n_get.t('Common.HsCodeSecondary')
    case 'itemsQuantity':
      return i18n_get.t('Common.ItemsQuantity')
    case 'fullItemsQuantity':
      return i18n_get.t('Common.Quantity')
    case 'catalogueId':
    case 'catalogue':
      return i18n_get.t('Common.CatalogueID')
    case 'procurement':
      return i18n_get.t('Common.ResponsibleProcurement')
    case 'registryComment':
      return i18n_get.t('Common.RegistryComment')
    case 'orderDetails':
      return i18n_get.t('Common.OrderDetails')
    case 'provider':
      return i18n_get.t('Common.Provider')
    case 'status':
      return i18n_get.t('Common.Status')
    case 'license':
      return i18n_get.t('Common.License')
    case 'url':
      return i18n_get.t('Common.ItemURL')
    case 'inbound':
      return i18n_get.t('Common.Inbound')
    case 'supplyChain':
      return i18n_get.t('Common.SupplyChain')
    case 'outbound':
      return i18n_get.t('Common.Outbound')
    case 'casNumber':
      return i18n_get.t('Common.CASNumber')
    case 'packing':
      return i18n_get.t('Common.Packing')
    case 'transporterId':
    case 'transporter':
      return i18n_get.t('Common.Transporter')
    case 'responsibleSCD':
    case 'scd':
      return i18n_get.t('Common.ResponsibleSCD')
    case 'lot':
      return i18n_get.t('Common.Lot')
    case 'source_number':
      return i18n_get.t('Common.SourceNumber')
    case 'locations':
    case 'location':
      return i18n_get.t('Warehouse.Location')
    case 'trackIds':
      return i18n_get.t('Common.TrackId')
    case 'trackNumber':
      return i18n_get.t('Common.TrackId')
    case 'invoiceName':
      return i18n_get.t('Common.ItemInvoiceName')
    case 'originalName':
      return i18n_get.t('Common.OriginalItemName')
    case 'country':
      return i18n_get.t('Common.Country')
    case 'invoiceNumber':
      return i18n_get.t('Common.InvoiceNumber')
    case 'orderNumber':
      return i18n_get.t('Common.OrderNumber')
    case 'procurementComment':
      return i18n_get.t('Common.ProcurementComment')
    case 'proposalComment':
      return i18n_get.t('Common.ProposalComment')
    case 'costPrice':
      return i18n_get.t('Common.CostPrice')
    case 'clientPrice':
      return i18n_get.t('Common.ItemPrice')
    case 'sumPrice':
      return i18n_get.t('Common.Sum')
    case 'statusId':
      return i18n_get.t('Common.Status')
    case 'exportComment':
      return i18n_get.t('Common.ExportComment')
    case 'countryOfOrigin':
      return i18n_get.t('Common.CountryOfOrigin')
    case 'hazmatComment':
      return i18n_get.t('Common.HazmatComment')
    case 'arrivalDate':
      return i18n_get.t('Common.ArrivalDate')
    case 'hazmat':
    case 'isHazmat':
    case 'providerHazmatType':
      return i18n_get.t('Common.Hazmat')
    case 'providerHSCode':
      return i18n_get.t('Common.ProviderHSCode')
    case 'orderComments':
      return i18n_get.t('Common.OrderComment')
    case 'currencySum':
      return i18n_get.t('Common.Sum')
    case 'invoicePrice':
    case 'coefInvoicePrice':
    case 'invoicePriceSum':
      return i18n_get.t('Common.InvoicePrice')
    case 'otherCost':
      return i18n_get.t('Common.OtherCost')
    case 'pricePerPiece':
    case 'invoicePricePerUnit':
      return i18n_get.t('Common.PricePerPiece')
    case 'tax':
      return i18n_get.t('Common.Tax')
    case 'cost':
    case 'itemCost':
      return i18n_get.t('Common.ItemCost')
    case 'sumCost':
      return i18n_get.t('Common.Sum')
    case 'temperature':
    case 'temperatureId':
    case 'temperatureRegime':
    case 'temperatures':
      return i18n_get.t('Common.Temperature')
    case 'tswName':
      return i18n_get.t('Common.TswName')
    case 'boxNumber':
      return i18n_get.t('Common.Box')
    case 'tag':
      return i18n_get.t('Common.Tag')
    case 'packageComment':
      return i18n_get.t('Common.PackageComment')
    case 'paymentMethodId':
      return i18n_get.t('Common.PaymentMethod')
    case 'paymentMethod':
      return i18n_get.t('Common.PaymentMethod')
    case 'compliance':
      return i18n_get.t('Common.Compliance')
    case 'complianceComment':
      return i18n_get.t('Common.ComplianceComment')
    case 'exportDocuments':
      return i18n_get.t('Common.ExportDocuments')
    case 'hsCodeChina':
      return i18n_get.t('Common.HsCodeChina')
    case 'headOfProcurementStatus':
      return i18n_get.t('Common.HeadOfProcurement')
    case 'headOfFinanceStatus':
      return i18n_get.t('Common.HeadOfFinance')
    case 'card':
      return i18n_get.t('Common.Card')
    case 'financeControlStatus':
      return i18n_get.t('Common.FinanceControl')
    case 'billNumber':
      return i18n_get.t('Common.BillNumber')
    case 'moderationComment':
      return i18n_get.t('Common.ModeratorComment')
    case 'seller':
      return i18n_get.t('Common.Seller')
    case 'dgmStatus':
      return i18n_get.t('Common.DgmStatus')
    case 'deliveryMethod':
      return i18n_get.t('Common.DeliveryMethod')
    case 'quota':
      return i18n_get.t('Common.Quota')
    case 'complianceDate':
    case 'complianceTimestamp':
      return i18n_get.t('Common.ComplianceDate')
    case 'estimatedOrderDate':
    case 'estimatedOrderTimestamp':
      return i18n_get.t('Common.EstimatedOrderDate')
    case 'paymentStatus':
      return i18n_get.t('Common.PaymentStatus')
    case 'financeControl':
      return i18n_get.t('Common.FinanceControl')
    case 'isTender':
      return i18n_get.t('Common.Tender')
    case 'invoicePriceOld':
      return i18n_get.t('Common.InvoicePriceOld')
    case 'clientType':
      return i18n_get.t('Common.ClientType')
    case 'trackToHub':
      return i18n_get.t('Common.TrackToHub')
    case 'price':
      return i18n_get.t('Common.Price')
    case 'customPrice':
      return i18n_get.t('Common.CustomPrice')
    case 'customPriceOld':
      return i18n_get.t('Common.CustomPriceOld')
    case 'tswed':
      return i18n_get.t('Common.Tswed')
    case 'tagToHub':
      return i18n_get.t('Common.TagToHub')
    case 'tagToTransit':
      return i18n_get.t('Common.TagToTransit')
    case 'trackToRu':
      return i18n_get.t('Common.TrackToRu')
    case 'tagToRu':
      return i18n_get.t('Common.TagToRu')
    case 'hub':
      return i18n_get.t('Common.Hub')
    case 'transit':
      return i18n_get.t('Common.Transit')
    case 'termsOfPayment':
      return i18n_get.t('Common.TermsOfPayment')
    case 'registryNumber':
      return i18n_get.t('Common.RegistryNumber')
    case 'invoiceCheck':
      return i18n_get.t('Common.InvoiceCheck')
    case 'invoiceComment':
      return i18n_get.t('Common.InvoiceComment')
    case 'paymentDeadline':
      return i18n_get.t('Common.PaymentDeadline')
    case 'prtComments':
      return i18n_get.t('Common.PrtComments')
    case 'netWeight':
      return i18n_get.t('Common.NetWeight')
    case 'grossWeight':
      return i18n_get.t('Common.GrossWeight')
    case 'financeControlComment':
      return i18n_get.t('Common.FinanceControlComment')
    case 'prepayment':
      return i18n_get.t('Common.Prepayment')
    case 'orderNumbering':
      return i18n_get.t('Common.NumberingOrder')
    case 'orderComment':
      return i18n_get.t('Common.OrderComment')
    case 'procurementDlReason':
      return i18n_get.t('Common.ProcurementDlReason')
    case 'procurementDlComments':
      return i18n_get.t('Common.ProcurementDlComments')
    case 'actuallyDelivered':
      return i18n_get.t('Provider.ToWarehouse.ActuallyDelivered')
    case 'orderInvoice':
      return i18n_get.t('Common.InvoiceNumber')
    case 'expirationDate':
      return i18n_get.t('Common.ExpirationDate')
    case 'sdComments':
      return i18n_get.t('Common.SDComment')
    case 'complianceComments':
      return i18n_get.t('Common.ComplianceComment')
    case 'procurementComments':
      return i18n_get.t('Common.ProcurementComment')
    case 'storeOrderNumber':
      return i18n_get.t('Common.OrderNumber')
    case 'hsnCodes':
      return i18n_get.t('Common.HsnCodes')
    case 'clientPaymentReceived':
      return i18n_get.t('Common.ClientPaymentReceived')
    case 'clientPaymentTerms':
      return i18n_get.t('Common.ClientPaymentTerms')
    case 'dlOrder':
      return i18n_get.t('Common.DLOrder')
    case 'prtChannelChangeReason':
      return i18n_get.t('Common.PrtChannelChangeReason')
    case 'isShippedInAdvance':
      return i18n_get.t('Common.SingleTracking')
    case 'track':
      return i18n_get.t('Provider.ToWarehouse.Track')
    case 'casId':
      return i18n_get.t('Provider.ToWarehouse.CasId')
    default:
      logError(`Unknown column id: "${id}"`)
      return EMPTY_STRING
  }
}
