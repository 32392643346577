import {createPrimitiveAtom} from '@reatom/core/primitives'
import {createSettingsAtom} from 'src/components/table/utils/settings-atom'
import { TABLE_ID } from 'src/components/table/utils/table-id'

//-----------------------ITEMS TABLE--------------------------
export type ArchiveViewItem = ArchiveItem

export interface ArchiveItem {
  id: string
  airtableLink: string
  departureDate: Date | null
  toWarehouseId: string
  transporterId: string
  amoId: string
  brand: string
  invoiceName: string
  originalName: string
  catalogueId: string
  temperatureId: string
  toHub0: Date | null
  hub0: string
  trackHub0: string
  tswed: Date | null
  hazmat: string
  hsCodeSecondary: string
  quantity: number
  store: string
  grossWeight: number
  netWeight: number
  invoicePrice: number
  lot: string
  source_number: string
  originCountry: string
  currencyId: string
  invoiceNumber: string
  expiredDate: Date | null
  exportStatuses: Array<string>
  exportComment: string
  boxNumber: Array<string>
  express: boolean
  locations: Array<string>
  prtComments: string
  providerTagToRu: string
  providerTagToTransit: string
  providerTagToHub: string
  responsibleSCD: string
  procurement: string
  sdExp: string
}

export const archiveItemsAtom = createPrimitiveAtom<
  Record<string, ArchiveItem>
>({})
export const archiveItemsTableSelectedPackageId = createPrimitiveAtom<
  string | null
>(null)

//-----------------------PACKAGES TABLE--------------------------
export type ArchiveViewPackage = ArchivePackage

export interface ArchivePackage {
  id: string
  departureDate: Date | null
  toWarehouseId: string
  tag: string
  transporter: string
  invoiceNumber: string
  trackIds: Array<string>
  temperatures: Array<string>
  boxNumber: Array<string>
  quantity: number
  arrivalDate: Date | null
  invoicePriceSum: number
  packageComment: string
  currencyId: string
  isHazmat: boolean
  providerTagToRu: string
  providerTagToTransit: string
  providerTagToHub: string
}

export const archivePackagesAtom = createPrimitiveAtom<
  Record<string, ArchivePackage>
>({})

export const archiveItemsSettings = createSettingsAtom({
  tableId: TABLE_ID.ARCHIVE_ITEMS,
  isLazyLoading: false,
})

export const archivePackagesSettings = createSettingsAtom({
  tableId: TABLE_ID.ARCHIVE_PACKAGES,
  isLazyLoading: false,
})
