import {createPrimitiveAtom} from '@reatom/core/primitives'
import {RowSelectionState} from '@tanstack/react-table'
import {createSettingsAtom} from 'src/components/table/utils/settings-atom'
import { TABLE_ID } from 'src/components/table/utils/table-id'

//-----------------------ITEMS TABLE--------------------------
export type ReadyToShipViewItem = ReadyToShipItem

export interface ReadyToShipItem {
  id: string
  airtableLink: string
  plannedDepartureDate: Date | null
  toWarehouseId: string
  store: string
  grossWeight: number
  netWeight: number
  amoId: string
  brand: string
  invoiceName: string
  toHub0: Date | null
  hub0: string
  trackHub0: string
  tswed: Date | null
  originalName: string
  catalogueId: string
  temperatureId: string
  invoiceNumber: string
  hazmat: string
  originCountry: string
  lot: string
  source_number: string
  quantity: number
  hsCodeSecondary: string
  invoicePrice: number
  currencyId: string
  exportStatuses: Array<string>
  exportComment: string
  boxNumber: Array<string>
  providerTagToHub: string
  providerTagToTransit: string
  providerTagToRu: string
  providerHub: string
  providerTransit: string
  procurementComment: string
  procurement: string
  responsibleSCD: string
  express: boolean
  locations: Array<string>
  prtComments: string
  sdExp: string
  additionalCosts: number
}

export const readyToShipItemsAtom = createPrimitiveAtom<
  Record<string, ReadyToShipItem>
>({})
export const readyToShipItemsTableSelectedIds =
  createPrimitiveAtom<RowSelectionState>({})
export const readyToShipItemsTableSelectedPackageId = createPrimitiveAtom<
  string | null
>(null)

//-----------------------PACKAGES TABLE--------------------------

export interface ReadyToShipPackage {
  id: string
  plannedDepartureDate: Date | null
  toWarehouseId: string
  fromWarehouseId: string
  tag: string
  temperatures: Array<string>
  boxNumber: Array<string>
  transporter: string
  trackIds: Array<string>
  quantity: number
  invoicePriceSum: number
  invoiceNumber: string
  packageComment: string
  isHazmat: boolean
  currencyId: string
  providerTagToHub: string
  providerTagToTransit: string
  providerTagToRu: string
  providerHub: string
  providerTransit: string
  procurementComment: string
  packageInvoice: string
  packageDate: string
  express: boolean
}

export const readyToShipPackagesAtom = createPrimitiveAtom<
  Record<string, ReadyToShipPackage>
>({})
export const readyToShipPackagesTableSelectedId =
  createPrimitiveAtom<RowSelectionState>({})

export const readyToShipItemsSettings = createSettingsAtom({
  tableId: TABLE_ID.READY_TO_SHIPS_ITEMS,
  isLazyLoading: false,
})

export const readyToShipPackagesSettings = createSettingsAtom({
  tableId: TABLE_ID.READY_TO_SHIPS_PACKAGES,
  isLazyLoading: false,
})
