import { indexBy, prop } from 'ramda'
import { createMultipleParam } from '@appscience/utils'
import { warehousesAtom } from '../../atoms/static-data/warehouses-atom'
import { API_URL } from 'src/config/environment'
import { InWarehouseItem } from '../../views/warehouse/in-warehouse/model/atoms'
import apiCreator from '../api-creator'
import { Api_Item } from '../common/item-model'
import { getItemInvoicePrice, handleErrorWithDefaultValue } from '../common/utils'
import { providersAtom } from 'src/atoms/static-data/providers-atom'
import { parseDate } from '@app/utils/date'

interface Params {
  warehouseIds: Array<string>
  send: boolean
  stock: boolean
}

export function getInWarehouseItems({
  warehouseIds,
  send,
  stock,
}: Params): Promise<Record<string, InWarehouseItem>> {
  return apiCreator
    .get(
      `${API_URL.TWIN}/items?step=registered&${createMultipleParam(
        warehouseIds,
        'warehouse_id',
      )}&stock=${stock}&send=${send}`,
    )
    .then(res => {
      const rawData: Array<Api_Item> = res.data.items
      return indexBy(prop('id'), rawData.map(remapInWarehouseItem))
    })
    .catch(handleErrorWithDefaultValue({}))
}

export function remapInWarehouseItem(data: Api_Item): InWarehouseItem {
  return {
    id: data.id,
    airtableLink: data.airtable_link,
    originalName: data.original_name,
    invoiceName: data.name,
    brand: data.brand,
    isStock: data.is_stock,
    amoId: data.deal_id,
    orderComments: data.order_comment,
    providerHSCode: data.hs_code,
    catalogueId: data.catalogue_id,
    sumCost: data.full_cost,
    itemCost: data.cost,
    provider: data.provider,
    procurement: data.procurement,
    responsibleSCD: data.responsible_scd,
    quantity: data.quantity,
    currencyId: data.currency,
    invoicePrice: getItemInvoicePrice(data) || 0,
    registrationDate: parseDate(data.registration_date),
    deadlineDate: data.is_stock
      ? undefined
      : parseDate(data.deadline_date) || undefined,
    temperatureRegime: data.provider_temp,
    hazmatComment: data.provider_hazmat,
    lot: data.provider_lot,
    source_number: data.source_number,
    locations: data.provider_locations,
    additionalCosts: data.additional_costs,
    extraChargePercent: data.extra_charge_percent,
    orderNumbering: data.order_numbering,
    expiredDate: parseDate(data.provider_exp),
    warehouseAreaId:
      getWarehouseAreaIdByProvider(data.provider)
      || getWarehouseAreaIdByHub(data.provider_hub),
    exportComment: data.provider_comment,
    countryOfOrigin: data.country_of_origin,
    exportStatuses: data.provider_export_statuses || [],
    boxNumber: data.box_numbers,
    sdComment: data.sd_comments,
    sdExp: String(data.sd_exp || '').trim(),
    tagToHub: data.provider_tag_to_hub || '',
    tagToTransit: data.provider_tag_to_transit || '',
    tagToRu: data.provider_tag_to_ru || '',
    hub: data.provider_hub || '',
    transit: data.provider_transit || '',
    prtComments: data.procurement_comment || '',
    express: data.express || false,
    providerHazmatType: data.provider_hazmat_type,
    hsnCodes: data.hsn_codes,
    store: data.store,
  }
}

function getWarehouseAreaIdByProvider(provider: string): string | null {
  const providerName = providersAtom.getState()[provider]?.name ?? ''

  return warehousesAtom.getState()[providerName]?.areaId || null
}

function getWarehouseAreaIdByHub(hub: string): string | null {
  return (
    Object.values(warehousesAtom.getState()).find(wrh => wrh.title === hub)
      ?.areaId || null
  )
}
